import React, { ReactChild } from 'react';
import { Modal, ModalUnstyledOwnProps, SxProps, Theme } from '@mui/material';
import { BoxCardS, BoxS } from './styles';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { ModalTitle, ModalTitleContainer } from '../OfacModal/styles';
import { ModalBackground } from '../../../Theme/colorsVariables';

export default function CustomModal({
  children,
  modalTitle,
  open,
  modalStyles,
  modalProps,
  containerWidth,
  bodyStyles,
}: {
  children: ReactChild;
  modalTitle: string;
  open: boolean;
  modalStyles?: SxProps<Theme>;
  bodyStyles?: SxProps<Theme>;
  containerWidth: string;
  modalProps?: Partial<ModalUnstyledOwnProps>;
}) {
  return (
    <Modal sx={{ ...modalStyles, background: ModalBackground }} open={open} disableAutoFocus>
      <BoxS onBlur={() => {}} sx={{ bgcolor: 'background.paper', width: containerWidth }}>
        <ModalTitleContainer>
          <ModalTitle>{modalTitle}</ModalTitle>
          {modalProps?.onClose && (
            <CancelRoundedIcon
              style={{ color: '#ffffff', cursor: 'pointer' }}
              onClick={(e) => {
                if (modalProps?.onClose) {
                  modalProps?.onClose(e, 'escapeKeyDown');
                }
              }}
            />
          )}
        </ModalTitleContainer>

        <BoxCardS sx={{ ...bodyStyles }}>{children}</BoxCardS>
      </BoxS>
    </Modal>
  );
}
