import { Box, Checkbox, Grid } from '@mui/material';
import PageHeaderContentHost from '../../../components/shared/AppPageHeaderContentHost';
import { AlignCenterBox } from '../../../components/shared/FlexBox';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TermsOfUseService } from '../../../services/TermsOfUse';
import { useToast } from '../../../hooks/useToast';
import { CheckboxMain } from '../../../components/PermissionSets/styles';
import { TermsOfUseHeading } from './styles';
import AppTooltip from '../../../components/shared/AppTooltip';
import usePermissions, { PermissionKeys } from '../../../hooks/usePermissions';
import { useTranslation } from 'react-i18next';
import AddDocumentIcon from '../../../components/assets/AddDocumentIcon';
import DownloadIcon from '../../../components/assets/DownloadIcon';
import RemoveDocumentIcon from '../../../components/assets/RemoveDocumentIcon';

export const TermsOfUse = () => {
  const { t } = useTranslation();
  const isEnabled = usePermissions(PermissionKeys.Configuration);
  const navigate = useNavigate();
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const toast = useToast();

  const [displayProviderTerms, setDisplayProviderTerms] = useState(true);
  const [displayAgentTerms, setDisplayAgentTerms] = useState(false);

  const { data: termsOfUse, refetch } = TermsOfUseService.useAll({
    enabled: isEnabled,
  });

  const { mutate: downloadTermsOfUseById } = TermsOfUseService.useDownload({
    onSuccess(data) {
      try {
        // Create a Blob object with the HTML content
        const blob = new Blob([data], { type: 'text/html' });

        // Create a temporary link element
        const link = document.createElement('a');

        // Set the href attribute to the URL of the Blob
        link.href = URL.createObjectURL(blob);

        // Set the download attribute to the desired filename
        link.download = 'Terms and Conditions.html';

        // Dispatch a click event on the link to trigger the download
        link.click();
        toast.success('File downloaded successfully.');
      } catch (err) {
        toast.error('An error occured while downloading.');
      }
    },
    onError(err) {
      toast.error(err.response.data.message);
    },
  });
  const { mutate: updateTermsOfUseById } = TermsOfUseService.useUpdate({
    onSuccess: () => {
      toast.success('Configuration updated successfully.');
      refetch();
    },
    onError(err) {
      toast.error(err.response.data.message);
    },
  });
  const { mutate: uploadTermsOfUseById } = TermsOfUseService.useUpload({
    onSuccess: () => {
      toast.success('File added successfully.');
      refetch();
    },
    onError(err) {
      toast.error(err.response.data.message);
    },
  });
  const { mutate: deleteTermsOfUseById } = TermsOfUseService.useDelete({
    onSuccess: () => {
      toast.success('File deleted successfully.');
      refetch();
    },
    onError(err) {
      toast.error(err.response.data.message);
    },
  });

  const providerTermsObject = useMemo(() => {
    return termsOfUse?.find((item) => item.associatedWith.name === 'Provider');
  }, [termsOfUse]);
  const agentTermsObject = useMemo(() => {
    return termsOfUse?.find((item) => item.associatedWith.name === 'Agency');
  }, [termsOfUse]);

  const setInputRef = (index: number) => (element: HTMLInputElement | null) => {
    if (element) {
      inputRefs.current[index] = element;
    }
  };

  const handleUploadClick = (index: number) => {
    if (inputRefs.current[index]) {
      inputRefs.current[index].click();
    }
  };

  function handleFileChange(event: ChangeEvent<HTMLInputElement>, termsId: number) {
    if (event.target.files) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      formData.append('fileName', file.name);
      uploadTermsOfUseById({ termsId, formData });
    }
  }

  useEffect(() => {
    setDisplayProviderTerms(!!providerTermsObject?.isDisplayed);
    setDisplayAgentTerms(!!agentTermsObject?.isDisplayed);
  }, [agentTermsObject?.isDisplayed, providerTermsObject?.isDisplayed]);

  useEffect(() => {
    if (!isEnabled) {
      navigate('/NotFound', { state: { error: t('restrictions.tooltip') } });
    }
  }, [isEnabled, navigate, t]);

  return (
    <>
      <PageHeaderContentHost name="Terms Of Use" />
      <Grid container spacing={2}>
        {termsOfUse?.map((item, index) => (
          <Grid item xs={12} key={item.id}>
            <AlignCenterBox>
              <TermsOfUseHeading mr={2}>
                {item.associatedWith.name === 'Agency' ? 'Agent' : item.associatedWith.name} Users
              </TermsOfUseHeading>
              <CheckboxMain
                label={'Display Terms Of Use'}
                control={
                  <Checkbox
                    color="success"
                    checked={
                      item.associatedWith.name === 'Agency'
                        ? displayAgentTerms
                        : displayProviderTerms
                    }
                    value={
                      item.associatedWith.name === 'Agency'
                        ? displayAgentTerms
                        : displayProviderTerms
                    }
                    onChange={(_, checked) => {
                      if (item.associatedWith.name === 'Agency') {
                        setDisplayAgentTerms(checked);
                      } else {
                        setDisplayProviderTerms(checked);
                      }
                      updateTermsOfUseById({ termsId: item.id, isDisplaying: checked });
                    }}
                  />
                }
              />
              {(item.associatedWith.name === 'Agency'
                ? displayAgentTerms
                : displayProviderTerms) && (
                <AlignCenterBox>
                  <>
                    <AppTooltip title={'Add Terms Of Use'}>
                      <Box onClick={() => handleUploadClick(index)} sx={{ cursor: 'pointer' }}>
                        <AddDocumentIcon />
                      </Box>
                    </AppTooltip>
                    <input
                      style={{ display: 'none' }}
                      type="file"
                      onChange={(e) => handleFileChange(e, item.id)}
                      ref={setInputRef(index)}
                      accept="text/html"
                    />
                  </>
                  <AppTooltip title={'Download Terms Of Use'}>
                    <Box
                      mx={1}
                      sx={{ cursor: 'pointer' }}
                      onClick={() => downloadTermsOfUseById({ termsId: item.id })}
                    >
                      <DownloadIcon />
                    </Box>
                  </AppTooltip>
                  <AppTooltip title={'Delete Terms Of Use'}>
                    <Box
                      sx={{ cursor: 'pointer' }}
                      onClick={() => deleteTermsOfUseById({ termsId: item.id })}
                    >
                      <RemoveDocumentIcon />
                    </Box>
                  </AppTooltip>
                </AlignCenterBox>
              )}
            </AlignCenterBox>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
