import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { AppActionButton } from '../../components/shared/AppActionButton';
import { AppCancelButton } from '../../components/shared/AppCancelButton';
import { AppEditButton } from '../../components/shared/AppEditButton';
import PageHeaderContentHost from '../../components/shared/AppPageHeaderContentHost';
import { PageHeaderContainer } from '../../components/shared/AppPageHeaderContentHost/styles';
import { AppResetButton } from '../../components/shared/AppResetButton';
import { AppSaveButton } from '../../components/shared/AppSaveButton';
import { FlexBox, JustifyContentRightBox } from '../../components/shared/FlexBox';
import { useToast } from '../../hooks/useToast';
import { User, UserPayload } from '../../types';
import usePermissions, { PermissionKeys } from '../../hooks/usePermissions';
import UserService from '../../services/userService';
import { UserForm } from '../../components/Forms/UserForm';
import UserActiveModal from '../../components/modal/UserActiveModal';
import { useMemo } from 'react';
import { StyledTypography } from '../../components/Forms/RoleForm/noPermissionsText';
import { Grid } from '@mui/material';
import { AppDeleteButton } from '../../components/shared/AppDeleteButton';
import DeleteEntityModal from '../../components/modal/DeleteEntityModal';
import { AppWarningParagraph } from '../../components/shared/AppWarningParagraph';
import { ChangePassButton } from '../../components/PermissionSets/styles';
import { StickyContainer } from '../../Theme/oldStylesInUse/oldAddSellerStyles';
import { useDataloss } from '../../hooks/useDataloss';
import { UserInvitationService } from '../../services/userServices';
import { QuickConfirmationModalContext } from '../../context/QuickModal';
import { UserContext } from '../../context/User';
import AppTooltip from '../../components/shared/AppTooltip';
import { useQueryClient } from 'react-query';
import { TabsComunicationChannel } from '../../providers';
import { ModificationLog } from '../../components/ModificationLog';

export const UserPage = () => {
  const { t } = useTranslation();
  const { userId } = useParams();
  const toast = useToast();
  const user = useContext(UserContext);
  const { setQuickModal } = useContext(QuickConfirmationModalContext);
  const navigate = useNavigate();
  const isReadProviderUserPermission = usePermissions(PermissionKeys.ReadProviderUser);
  const isReadAgentUserPermission = usePermissions(PermissionKeys.ReadAgentUser);
  const isDeleteProviderUserPermission = usePermissions(PermissionKeys.DeleteProviderUser);
  const isDeleteAgentUserPermission = usePermissions(PermissionKeys.DeleteAgentUser);
  const isEditProviderUserPermission = usePermissions(PermissionKeys.UpdateProviderUser);
  const isEditAgentUserPermission = usePermissions(PermissionKeys.UpdateAgentUser);
  const [isEdit, setIsEdit] = useState(false);
  const [isUnsavedData, setIsUnsavedData] = useState(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [cancelPopup, setCancelPopUp] = useState(false);
  const [userStatus, setUserStatus] = useState(false);
  const [isUserStatusDeactivateModalOpened, setIsUserStatusDeactivateModalOpened] = useState(false);
  const [isDeleteUserModalOpen, setIsDeleteUserModalOpen] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);
  const {
    data: userData,
    isFetching: isUserDataFetching,
    refetch: refetchUserData,
  } = UserService.useUserQuery(Number(userId));
  const isViewUserPermission: true | false | undefined = useMemo(() => {
    if (userData && userData.orgTypeName === 'Provider') {
      return isReadProviderUserPermission;
    }
    if (userData && userData.orgTypeName === 'Agency') {
      return isReadAgentUserPermission;
    }
  }, [isReadProviderUserPermission, isReadAgentUserPermission, userData]);
  const isDeleteUserPermission = useMemo(() => {
    if (user?.userId === userData?.userId) {
      return true;
    }
    if (userData && userData.orgTypeName === 'Provider') {
      return !isDeleteProviderUserPermission;
    }
    if (userData && userData.orgTypeName === 'Agency') {
      return !isDeleteAgentUserPermission;
    }
  }, [user?.userId, userData, isDeleteProviderUserPermission, isDeleteAgentUserPermission]);
  const isUpdateUserPermission = useMemo(() => {
    if (userData && userData.orgTypeName === 'Provider') {
      return !isEditProviderUserPermission;
    }
    if (userData && userData.orgTypeName === 'Agency') {
      return !isEditAgentUserPermission;
    }
  }, [isEditProviderUserPermission, isEditAgentUserPermission, userData]);
  const onInviteSuccess = (response: boolean) => {
    if (response) {
      toast.success(t('toast.uset-form.success'));
    } else {
      toast.error('error sent invite');
    }
  };

  const { mutate: SendUserInvite } = UserInvitationService({
    onSuccess: onInviteSuccess,
  });
  // Get QueryClient from the context
  const queryClient = useQueryClient();

  const handleSuccess = async (response: User) => {
    toast.success(t('toast.update-user.success'));
    setIsEdit(false);
    refetchUserData();

    if (user?.email === response.email) {
      // user?.refetchUserData();
      queryClient.invalidateQueries('user');
      TabsComunicationChannel.postMessage('permissionsChange');
    }
  };

  const { mutate: userUpdate } = UserService.useUserUpdateQuery({
    onSuccess: handleSuccess,
    onError(error) {
      if (error.response.data.message.includes('At least one person in the organization')) {
        setQuickModal({
          isOpen: true,
          message: error.response.data.message,
          modalTitle: 'Error!',
        });
        return;
      }
      toast.error(error.response.data.message);
    },
  });
  const { mutate: deleteUser } = UserService.useUserDeleteQuery({
    onSuccess() {
      toast.success(t('security.users.userList.deleteMsg'));
      navigate('/dashboard/User');
    },
    onError(error) {
      setQuickModal({ isOpen: true, message: error.response.data.message, modalTitle: 'Error' });
    },
  });

  const ownUser = useMemo(() => {
    return userData?.userId === user?.userId;
  }, [user?.userId, userData?.userId]);

  const handleConfirm = useCallback(() => {
    setUserStatus((prev) => !prev);
    setIsUserStatusDeactivateModalOpened(false);
  }, []);

  const handleModal = useCallback(() => {
    setIsUserStatusDeactivateModalOpened(false);
  }, []);

  const handleFormSubmit = (data: UserPayload) => {
    userUpdate(data);
  };

  const handleDeleteUser = useCallback(() => {
    setIsDeleteUserModalOpen(false);
    deleteUser({ userId: Number(userId) });
  }, [deleteUser, userId]);

  useEffect(() => {
    if (userData) {
      setUserStatus(userData.status === 1);
    }
  }, [userData]);

  const { mutate: changeUserPasswordLink } = UserService.usePasswordLinkQuery();

  const onDiscardChanges = useCallback(() => {
    setIsEdit(false);
    setCancelPopUp(false);
    formRef.current?.dispatchEvent(new Event('reset', { cancelable: true, bubbles: true }));
  }, [formRef, setIsEdit]);

  const onSaveChanges = useCallback(() => {
    setCancelPopUp(false);
    if (formRef && formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }, [formRef, setCancelPopUp]);

  const onExit = useCallback(() => {
    setCancelPopUp(false);
  }, [setCancelPopUp]);

  useDataloss(isUnsavedData && isEdit, onDiscardChanges, onSaveChanges, onExit);

  if (isViewUserPermission !== true && isViewUserPermission !== undefined) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <StyledTypography mt={10}>{t('dashboard.products.title')}</StyledTypography>
        </Grid>
      </Grid>
    );
  }

  if (isUserDataFetching) {
    return <></>;
  }

  return userData ? (
    <>
      <StickyContainer>
        <PageHeaderContainer>
          <FlexBox>
            <PageHeaderContentHost
              title={'User'}
              name={userData?.firstName + ' ' + userData?.lastName}
              status={userStatus}
              showRequiredLabel={isEdit}
            />
            {isEdit && (
              <AppTooltip
                title={
                  user?.userId === userData.userId
                    ? t('security.users.userList.ownUserStatus')
                    : null
                }
              >
                <span>
                  <AppActionButton
                    variant="outlined"
                    disabled={user?.userId === userData.userId}
                    onClick={() => {
                      if (userStatus) {
                        setIsUserStatusDeactivateModalOpened(true);
                        return;
                      }
                      setUserStatus((prev) => !prev);
                    }}
                  >
                    {!userStatus ? (
                      <span>{t('security.users.userList.activate')}</span>
                    ) : (
                      <span>{t('security.users.userList.deactivate')}</span>
                    )}
                  </AppActionButton>
                </span>
              </AppTooltip>
            )}
          </FlexBox>
          {!isEdit && (
            <FlexBox>
              <AppEditButton
                onClick={() => {
                  setIsEdit(true);
                }}
                disabled={isUpdateUserPermission}
              />

              <AppTooltip
                title={
                  user?.userId === userData.userId
                    ? t('security.users.userList.ownUserDelete')
                    : null
                }
              >
                <span>
                  <AppDeleteButton
                    onClick={() => {
                      setIsDeleteUserModalOpen(true);
                    }}
                    disabled={isDeleteUserPermission}
                  />
                </span>
              </AppTooltip>

              <ChangePassButton
                onClick={() =>
                  changeUserPasswordLink({
                    mailId: userData.email,
                    userName: userData.firstName + ' ' + userData.lastName,
                  })
                }
                disabled={
                  userData.status === 1 && userData.authUserId !== '' && userData.isUserVerified
                    ? false
                    : true
                }
                variant="outlined"
              >
                {t('user.list.change.password')}
              </ChangePassButton>
              <AppActionButton
                variant="outlined"
                disabled={
                  (userData?.isUserVerified === true && userData?.authUserId !== null) ||
                  userData.status === 0
                }
                onClick={() => {
                  SendUserInvite(userData.userId.toString());
                }}
              >
                Resend Invitation
              </AppActionButton>
            </FlexBox>
          )}
          {isEdit && (
            <FlexBox>
              <AppCancelButton
                onClick={() => {
                  if (isUnsavedData) {
                    setCancelPopUp(true);
                  } else {
                    setIsEdit(false);
                  }
                }}
              />
              <AppResetButton
                onClick={() => {
                  formRef.current?.dispatchEvent(
                    new Event('reset', { cancelable: true, bubbles: true }),
                  );
                }}
              />
              <AppSaveButton
                disabled={isSaveEnabled}
                onClick={() => {
                  formRef.current?.dispatchEvent(
                    new Event('submit', { cancelable: true, bubbles: true }),
                  );
                }}
              />
            </FlexBox>
          )}
        </PageHeaderContainer>
      </StickyContainer>
      {userData?.isUserVerified === true && userData?.authUserId !== null && (
        <JustifyContentRightBox>
          <AppWarningParagraph>
            <i>{t('security.users.userForm.registered')}</i>
          </AppWarningParagraph>
        </JustifyContentRightBox>
      )}

      <UserForm
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        defaultValues={userData}
        onSubmit={handleFormSubmit}
        formRef={formRef}
        isSaveEnabled={isSaveEnabled}
        setIsSaveEnabled={setIsSaveEnabled}
        isUnsavedData={isUnsavedData}
        setIsUnsavedData={setIsUnsavedData}
        userStatus={userStatus}
        setUserStatus={setUserStatus}
        cancelPopup={cancelPopup}
        setCancelPopup={setCancelPopUp}
      />

      {!isEdit && (
        <ModificationLog
          {...{
            createdBy: userData.createdBy,
            createdOn: userData.createdOn,
            updatedBy: userData.updatedBy,
            updatedOn: userData.updatedOn,
          }}
        />
      )}

      <UserActiveModal
        popUp={isUserStatusDeactivateModalOpened}
        ownUser={ownUser}
        handleConfirm={handleConfirm}
        handleModal={handleModal}
      />
      <DeleteEntityModal
        entityName={userData?.firstName + ' ' + userData?.lastName}
        entityType={'User'}
        onConfirm={handleDeleteUser}
        open={isDeleteUserModalOpen}
        setOpen={setIsDeleteUserModalOpen}
      />
    </>
  ) : (
    <></>
  );
};
