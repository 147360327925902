import React, { useRef, useState, useContext, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  FormControl,
  TableRow,
  TableCell,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import AgreementTemplateService from '../../services/documentService';
import ProductService from '../../services/productService';
import CustomTableSmall from '../../components/table/CustomTableSmall';
import AddTemplate from '../../components/modal/AddTemplate';
import {
  ApiError,
  DocumentItem,
  ProductDetails,
  ProductDocument,
  AddressState,
  TemplateDocumentPayload,
  TemplateDocumentResponse,
} from '../../types';
import AuthService from '../../services/authService';
import { NewProductWithStates } from '../../types/product-types';
import StatesMultipleSelect from '../../components/shared/StatesMultipleSelectChips';
import usePermissions, { PermissionKeys } from '../../hooks/usePermissions';
import { useToast } from '../../hooks/useToast';
import { useDataloss } from '../../hooks/useDataloss';
import {
  AppActionButton,
  AppActionButtonsContainer,
} from '../../components/shared/AppActionButton';
import PageHeaderContentHost from '../../components/shared/AppPageHeaderContentHost';
import { AppTextField } from '../../components/shared/AppTextField';
import { AddTemplateLabel, DeleteTemplateLabel } from '../AgreementTemplateList/style';
import { PageHeaderContainer } from '../../components/shared/AppPageHeaderContentHost/styles';
import { isEqual } from 'lodash';
import {
  AlignCenterBox,
  CenterCenterBox,
  FlexBox,
  JustifyContentRightBox,
} from '../../components/shared/FlexBox';
import { AppWarningParagraph } from '../../components/shared/AppWarningParagraph';
import { AppEditButton } from '../../components/shared/AppEditButton';
import { AppDeleteButton } from '../../components/shared/AppDeleteButton';
import DeleteEntityModal from '../../components/modal/DeleteEntityModal';
import { AppCancelButton } from '../../components/shared/AppCancelButton';
import { AppResetButton } from '../../components/shared/AppResetButton';
import { AppSaveButton } from '../../components/shared/AppSaveButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { productLineValidationSchema } from '../../Resolvers/productLineValidationSchema';
import { CancelModal } from '../../components/modal/CancelModal';
import { NoPermissionMessage } from '../../components/shared/NoPermissionMessage';
import { QuickConfirmationModalContext } from '../../context/QuickModal';
import { UserContext } from '../../context/User';
import { ErrorMessageLabel } from '../../components/modal/AddTemplate/styles';
import { AgreementTemplateNew } from '../../components/modal/AddTemplate/types';
import EditDocumentIcon from '../../components/assets/EditDocumentIcon';
import RemoveDocumentIcon from '../../components/assets/RemoveDocumentIcon';
import AddDocumentIcon from '../../components/assets/AddDocumentIcon';
import EditTemplateModal from '../../components/modal/EditTemplate';
import { Order } from '../../types/query-params';
import NotificationHub from '../../hooks/useNotificationHub';
import AppTooltip from '../../components/shared/AppTooltip';
import { useNavigateAfterDatalossDecision } from '../../hooks/useNavigateAfterDatalossDecision';
import { ModificationLog } from '../../components/ModificationLog';
import DownloadIcon from '../../components/assets/DownloadIcon';
import EditIcon from '../../components/assets/EditIcon';
import ViewIcon from '../../components/assets/ViewIcon';

type FormValues = {
  description: string;
  productName: string;
  states: AddressState[];
  status: boolean;
};

function ViewProduct() {
  const theme = useTheme();

  const isSmall = useMediaQuery(theme.breakpoints.down('xl'));

  const isUpdateProductPermission = usePermissions(PermissionKeys.ModifyProducts);
  const isDeleteProductPermission = usePermissions(PermissionKeys.DeleteProducts);
  const isUpdateAgreementPermission = usePermissions(PermissionKeys.ModifyAgreement);
  const isAddAgreementPermission = usePermissions(PermissionKeys.AddAgreement);
  const isViewAgreementPermission = usePermissions(PermissionKeys.ViewAgreement);
  const isDeleteAgreementPermission = usePermissions(PermissionKeys.DeleteAgreement);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { navigateAfterDatalossDecision } = useNavigateAfterDatalossDecision();

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      productName: '',
      description: '',
      states: [],
      status: false,
    },
    resolver: yupResolver(productLineValidationSchema),
  });

  const [stateIds, productName, description, status] = watch([
    'states',
    'productName',
    'description',
    'status',
  ]);

  const [originalProductData, setOriginalProductData] = useState<FormValues>();
  const [showAddTemplateModal, setShowAddTemplateModal] = useState<boolean>(false);
  const [showEditTemplateModal, setShowEditTemplateModal] = useState<boolean>(false);
  const [deletedTemplateId, setDeletedTemplateId] = useState(0);
  const [isProductEditable, setProductEditable] = useState<boolean>(false);
  const [documentObj, setDocumentObj] = useState<DocumentItem>({
    documentTypeId: 0,
    'document type': '',
    'used for entity': '',
    product: '',
    documentId: 0,
    productId: 0,
    documentOrgId: 0,
  });

  const [cancelPopUp, setCancelPopUp] = useState<boolean>(false);
  const toast = useToast();

  const valueRef = useRef<any>();
  const formRef = useRef<HTMLFormElement>(null);
  const { id } = useParams();

  const user = useContext(UserContext);
  const { data: statesResponse } = AuthService.useStatesQuery();
  const { data: productData, refetch } = ProductService.useProductDetailQuery();
  const { data: sellerStatusData, refetch: fetchSellerStatus } = ProductService.useProductStatus(
    productData?.productId,
  );

  const statesArray = useMemo(() => (statesResponse ? statesResponse : []), [statesResponse]);

  const [documents, setDocument] = useState<ProductDocument[]>([]);
  const [productDeleteModal, setProductDeleteModal] = useState(false);
  const [agreementDeleteModal, setAgreementDeleteModal] = useState(false);
  const [isUnsavedChanges, setIsUnsavedChanges] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [showReqdStateMessage, setReqdStateMessage] = useState<boolean>(false);

  const { mutate: deleteProduct } = ProductService.useDeleteProduct(
    () => {
      setProductDeleteModal(false);
      toast.success(`Product line ${productData?.productName} deleted successfully`);
      navigate('/dashboard/ProductLine');
    },
    (error) => {
      setProductDeleteModal(false);
      toast.error(error.response.data.message);
    },
  );

  const { mutate: deleteTemplate } = AgreementTemplateService.useDeleteTemplate(
    () => {
      setAgreementDeleteModal(false);
      toast.success(t('template-removed-message'));
      refetch();
    },
    (error) => {
      setAgreementDeleteModal(false);
      toast.error(error.response.data.message);
    },
  );

  useEffect(() => {
    const isValuesEqual = isEqual(originalProductData, {
      states: stateIds,
      productName,
      description,
      status,
    });

    setIsUnsavedChanges(!isValuesEqual);

    setIsSaveDisabled(isValid ? isValuesEqual === isValid : true);
  }, [description, isValid, originalProductData, productName, stateIds, status]);

  useEffect(() => {
    if (productData) {
      setDocument(productData.documents);
      fetchSellerStatus();
    }
  }, [fetchSellerStatus, productData]);
  const tableHeadingsArray: string[] = t('product.viewproduct.document.table', {
    returnObjects: true,
  });

  const tableSortingHeadingsObj: Record<
    'Agreement Type' | 'Used For Entity' | 'Template ID',
    keyof Pick<ProductDocument, 'documentTypeName' | 'usedForEntity' | 'documentOrgId'>
  > = {
    'Agreement Type': 'documentTypeName',
    'Used For Entity': 'usedForEntity',
    'Template ID': 'documentOrgId',
  };

  const onTemplateAddSuccess = (data: TemplateDocumentResponse) => {
    toast.success(t('template-added-message'));
    setDocument([]);
    refetch();
    window.open(data?.previewURL, '_blank', 'noreferrer');
  };

  const setFormDataValues = useCallback(() => {
    setValue('productName', productData?.productName || '', { shouldValidate: true });
    setValue('description', productData?.description || '', { shouldValidate: true });

    let allStatesDictionary: Record<number, AddressState> = {};
    statesArray?.forEach((state) => {
      allStatesDictionary = { ...allStatesDictionary, [state.addressStateId]: state };
    });
    const states =
      productData?.productAddressStates
        .map(({ addressStateId }) => {
          return allStatesDictionary[addressStateId];
        })
        .sort((a, b) => `${a.stateName}`.localeCompare(`${b.stateName}`)) || [];
    setValue('states', states);
    setValue('status', !!productData?.status);
    setReqdStateMessage(false);
    setOriginalProductData({
      productName: productData?.productName || '',
      description: productData?.description || '',
      states: states,
      status: !!productData?.status,
    });
  }, [productData, setValue, statesArray]);

  const onProductUpdateSuccess = (): void => {
    window.scrollTo(0, 0);
    toast.success(
      `${t('toast.productline.message')} ${productName} ${t('toast.productline.message.updated')}`,
    );
    navigateAfterDatalossDecision();
    setReqdStateMessage(false);
    setDocument([]);
    refetch();
    setProductEditable(false);
  };

  const onHandleAddTemplateError = (error: ApiError) => {
    toast.error(error.response.data.message);

    setShowAddTemplateModal(false);
  };

  const { mutate: addDocumentTemplate, isLoading: isAddDocumentLoading } =
    AgreementTemplateService.useAddTemplate(onTemplateAddSuccess, onHandleAddTemplateError);

  const { mutate: editDocumentOrgId } = AgreementTemplateService.useAddTemplate(
    () => {
      setShowEditTemplateModal(false);
      toast.success('Agreement Document Template ID updated');
      setTimeout(() => {
        refetch();
      }, 1000);
    },
    (error) => {
      toast.error(error.response.data.message);
    },
  );

  const { setQuickModal } = useContext(QuickConfirmationModalContext);

  const onHandleApiError = (error: ApiError, variables: NewProductWithStates) => {
    if (error.response.data.code === 30050) {
      setQuickModal({
        message: `Product Line ${variables.productName} already exists.`,
        modalTitle: 'Duplicates!',
        isOpen: true,
      });
    } else {
      toast.error(error.response.data.message);
    }
    setShowAddTemplateModal(false);
  };

  const { mutate: updateProductCall } = ProductService.useUpdateProductQuery(
    onProductUpdateSuccess,
    onHandleApiError,
  );

  const onEditTemplateSuccess = (data: string) => {
    window.open(data, '_blank', 'noreferrer');
  };
  const onEditTemplateError = (error: ApiError) => {
    toast.error(error.response.data.message);
  };

  const onBusinessError = () => {
    toast.error('toast.default.message.500');
  };
  const { mutate: editTemplate } = AgreementTemplateService.useEditTemplate(
    onEditTemplateSuccess,
    onEditTemplateError,
  );

  const onViewTemplateSuccess = (data: Blob) => {
    const url = window.URL.createObjectURL(data);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.target = '_blank';
    anchor.click();
  };

  const { mutate: viewTemplate } = AgreementTemplateService.useViewTemplate(
    onViewTemplateSuccess,
    onBusinessError,
  );

  const { mutate: downloadTemplate } = AgreementTemplateService.useDownload();

  const onSubmitAddTemplate = async (templateData: AgreementTemplateNew) => {
    setShowAddTemplateModal(false);

    const data: TemplateDocumentPayload = {
      templateName: templateData.fileName,
      desc: templateData.fileName,
      redirectUri: process.env.REACT_APP_DOCUSIGN_REDIRECT_URL,
      documentOrgId: templateData.documentOrgId,
      productId: templateData.productId,
      uploadDocs: {
        documentItems: [
          {
            fileName: templateData.fileName,
            documentId: templateData.documentId,
            documentTypeId: templateData.documentTypeId,
            usedForEntity: templateData.usedForEntity,
            base64String: templateData.templateJson.split(',')[1],
            fileExtension: 'pdf',
          },
        ],
      },
    };

    addDocumentTemplate(data);
    // UpdateDocumentTemplateList(listData);
  };

  useEffect(() => {
    setFormDataValues();
  }, [productData, setFormDataValues]);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (status && !stateIds.length) {
      setReqdStateMessage(true);
      return;
    }
    const selectedAddressStates = stateIds.map((item) => {
      const stateObj = { addressStateId: item.addressStateId };
      return stateObj;
    });
    const dataToPost: NewProductWithStates = {
      ...data,
      status: status,
      productId: productData?.productId,
      states: selectedAddressStates,
    };

    updateProductCall(dataToPost);
  };
  const useSortableData = (items: ProductDocument[]) => {
    const [sortConfig, setSortConfig] = React.useState<{
      key: keyof Pick<ProductDocument, 'documentTypeName' | 'usedForEntity' | 'documentOrgId'>;
      direction: Order;
      sortColumn: keyof typeof tableSortingHeadingsObj;
    }>({
      key: tableSortingHeadingsObj['Agreement Type'],
      direction: Order.ASC,
      sortColumn: 'Agreement Type',
    });

    const sortedItems = React.useMemo(() => {
      const sortableItems = items ? [...items] : [];

      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          const left = a[sortConfig.key]?.toString() || '';
          const right = b[sortConfig.key]?.toString() || '';
          if (sortConfig.direction === Order.ASC) {
            if (left === right && sortConfig.key === 'documentTypeName') {
              return a.usedForEntity.localeCompare(b.usedForEntity);
            }
            if (left === right && sortConfig.key === 'usedForEntity') {
              return a.documentTypeName.localeCompare(b.documentTypeName);
            }
            return left.localeCompare(right);
          }
          if (left === right && sortConfig.key === 'documentTypeName') {
            return a.usedForEntity.localeCompare(b.usedForEntity);
          }
          if (left === right && sortConfig.key === 'usedForEntity') {
            return a.documentTypeName.localeCompare(b.documentTypeName);
          }
          return right.localeCompare(left);
        });
      }
      return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key: keyof typeof tableSortingHeadingsObj) => {
      const updatedKey = tableSortingHeadingsObj[key];
      let direction = Order.ASC;
      if (sortConfig && sortConfig.key === updatedKey && sortConfig.direction === Order.ASC) {
        direction = Order.DESC;
      }

      setSortConfig({ key: updatedKey, direction, sortColumn: key });
    };
    return { items: sortedItems, requestSort, sortConfig };
  };

  const { items, requestSort, sortConfig } = useSortableData(productData?.documents || []);

  useEffect(() => {
    setDocument(items);
  }, [items]);

  const onSubmitType = (sortType: keyof typeof tableSortingHeadingsObj) => {
    requestSort(sortType);
  };

  const handleCancelAction = () => {
    if (isUnsavedChanges) {
      setCancelPopUp(true);
    } else {
      setProductEditable(false);
    }
  };

  const onDiscardChanges = useCallback(() => {
    setCancelPopUp(false);
    setProductEditable(false);
    setFormDataValues();
  }, [setFormDataValues]);

  const onSaveChanges = useCallback(() => {
    setCancelPopUp(false);
    if (formRef && formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }, []);

  const onExit = useCallback(() => {
    setCancelPopUp(false);
  }, []);

  useDataloss(isProductEditable && isUnsavedChanges, onDiscardChanges, onSaveChanges, onExit);

  const isDeletePermission = useMemo(() => {
    if (!isDeleteProductPermission) return true;
    if (isDeleteProductPermission && sellerStatusData?.hasSellerAgreementInProcess) return true;

    return false;
  }, [isDeleteProductPermission, sellerStatusData?.hasSellerAgreementInProcess]);

  const onAddDocument = (item: ProductDocument, nameOfProduct: string) => {
    setShowAddTemplateModal(true);
    setDocumentObj({
      documentTypeId: item.documentTypeId,
      'document type': item.documentTypeName,
      'used for entity': item.usedForEntity,
      product: nameOfProduct,
      documentId: item.documentId,
      documentOrgId: item.documentOrgId,
      productId: item.productId,
    });
    setDeletedTemplateId(0);
  };
  const onEditTemplateDocument = (item: ProductDocument, index: number, nameOfProduct: string) => {
    setShowEditTemplateModal(true);
    setDocumentObj({
      documentTypeId: item.documentTypeId,
      'document type': item.documentTypeName,
      'used for entity': item.usedForEntity,
      product: nameOfProduct,
      documentId: item.documentId,
      documentOrgId: item.documentOrgId,
      productId: item.productId,
    });
  };

  const onEditDocument = (item: ProductDocument, index: number, product: ProductDetails) => {
    const data = {
      redirectUri: `${process.env.REACT_APP_DOCUSIGN_REDIRECT_URL}?templateId=${
        item.templateId
      }&productId=${!!item.productId}`,
      tempOrEnvId: item.templateId,
    };
    editTemplate(data);
    setDocumentObj({
      documentTypeId: item.documentTypeId,
      'document type': item.documentTypeName,
      'used for entity': item.usedForEntity,
      product: product?.productName,
      documentId: item.documentId,
      documentOrgId: item.documentOrgId,
      productId: item.productId,
    });
  };

  const onViewDocument = (item: ProductDocument, index: number, product: ProductDetails) => {
    const data = {
      documentId: item?.documentId,
    };
    viewTemplate(data);
    setDocumentObj({
      documentTypeId: item.documentTypeId,
      'document type': item.documentTypeName,
      'used for entity': item.usedForEntity,
      product: product?.productName,
      documentId: item.documentId,
      productId: item.productId,
      documentOrgId: item.documentOrgId,
    });
  };

  const onDeleteDocument = () => {
    const data = {
      documentId: documentObj.documentId,
      loggedInUser: user?.userId || 0,
    };
    deleteTemplate(data);
  };

  const handleDeleteProduct = () => {
    deleteProduct({
      productId: Number(id),
      loggedInUser: user?.userId || 0,
    });
  };

  const { events } = NotificationHub();
  useEffect(() => {
    events((documentId) => {
      setDeletedTemplateId(documentId);
      refetch();
    });
  }, [events, refetch]);

  useEffect(() => {
    if (deletedTemplateId) {
      const productDocument = documents.find((doc) => doc.documentId === deletedTemplateId);
      if (productDocument && productData?.productName)
        onAddDocument(productDocument, productData?.productName);
    }
  }, [deletedTemplateId, documents, productData?.productName]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        <PageHeaderContainer mb={3}>
          <FlexBox>
            <PageHeaderContentHost
              name={productData?.productName}
              title={'product.viewproduct.title'}
              status={status}
              showRequiredLabel={isProductEditable}
            />
            {isProductEditable && (
              <AppActionButton
                onClick={() => {
                  setReqdStateMessage(false);
                  if (!status && !stateIds.length) {
                    setReqdStateMessage(true);
                    return;
                  }
                  setValue('status', !status);
                }}
              >
                {status
                  ? t('security.users.userList.deactivate')
                  : t('security.users.userList.activate')}
              </AppActionButton>
            )}
          </FlexBox>

          {!isProductEditable && (
            <Box>
              <AppTooltip title={!isUpdateProductPermission && t('restrictions.tooltip')}>
                <span>
                  <AppEditButton
                    disabled={!isUpdateProductPermission}
                    onClick={() => setProductEditable(true)}
                  />
                </span>
              </AppTooltip>
              <AppDeleteButton
                onClick={() => setProductDeleteModal(true)}
                disabled={isDeletePermission}
              />

              <DeleteEntityModal
                open={productDeleteModal}
                setOpen={setProductDeleteModal}
                entityName={productData?.productName || ''}
                entityType={'Product Line'}
                onConfirm={handleDeleteProduct}
              />
            </Box>
          )}

          {isProductEditable && (
            <AppActionButtonsContainer>
              <AppCancelButton onClick={handleCancelAction} />
              <AppResetButton
                onClick={() => {
                  setFormDataValues();
                  window.scrollTo(0, 0);
                }}
              />
              <AppSaveButton type="submit" disabled={isSaveDisabled} />
            </AppActionButtonsContainer>
          )}
        </PageHeaderContainer>
        {!isProductEditable && sellerStatusData?.hasSellerAgreementInProcess && (
          <JustifyContentRightBox>
            <AppWarningParagraph>
              <i>{t('product.delete.warning')}</i>
            </AppWarningParagraph>
          </JustifyContentRightBox>
        )}
        <Grid container my={2} spacing={isSmall ? 1 : 2}>
          <Grid item xs={6}>
            <Controller
              control={control}
              name="productName"
              render={({ field }) => (
                <AppTextField
                  {...field}
                  type="text"
                  label="Product Line"
                  required={isProductEditable}
                  disabled={!isProductEditable}
                  variant="outlined"
                  onChange={(e: { target: { value: string } }) => {
                    setValue('productName', e.target.value, { shouldValidate: true });
                  }}
                  errorMessage={errors.productName?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Box>
              <FormControl fullWidth>
                <StatesMultipleSelect<AddressState>
                  disabled={!isProductEditable}
                  originalData={statesArray}
                  selectedData={stateIds}
                  handleChangeSelected={(data) => setValue('states', data)}
                />
              </FormControl>
              {isProductEditable && showReqdStateMessage ? (
                <ErrorMessageLabel>
                  At least one state must be selected to activate a Product Line.
                </ErrorMessageLabel>
              ) : null}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <AppTextField
                  {...field}
                  type="text"
                  label="Description"
                  variant="outlined"
                  multiline
                  disabled={!isProductEditable}
                  fullWidth
                  minRows={4}
                  maxRows={4}
                  onChange={(e: { target: { value: string } }) => {
                    setValue('description', e.target.value, { shouldValidate: true });
                  }}
                  error={!!errors.description?.message}
                  errorMessage={errors.description?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </form>
      {productData && productData.documents.length > 0 ? (
        <>
          <CustomTableSmall
            goError={false}
            page={0}
            rowsPerPage={4}
            data={productData.documents}
            valueRef={valueRef}
            tableHeadingsArray={tableHeadingsArray}
            showPaginationContainer={false}
            onSubmitType={onSubmitType}
            order={sortConfig.direction}
            orderBy={sortConfig.sortColumn}
          >
            {documents.map((item: ProductDocument, index: number) => (
              <TableRow key={item.documentTypeName + item.usedForEntity}>
                <TableCell align="left">{item.documentTypeName}</TableCell>
                <TableCell align="left">{item.usedForEntity}</TableCell>
                <TableCell align="left">
                  <AlignCenterBox>
                    <Box sx={{ width: '100px' }}>{item.documentOrgId}</Box>
                    {item.templateId && isUpdateAgreementPermission && (
                      <AppTooltip title={'Edit Template ID'}>
                        <CenterCenterBox
                          ml={2}
                          sx={{ cursor: 'pointer' }}
                          onClick={() => {
                            onEditTemplateDocument(item, index, productData.productName);
                          }}
                        >
                          <EditIcon />
                        </CenterCenterBox>
                      </AppTooltip>
                    )}
                  </AlignCenterBox>
                </TableCell>
                {item.templateId &&
                (isViewAgreementPermission ||
                  isUpdateAgreementPermission ||
                  isDeleteAgreementPermission) ? (
                  <TableCell>
                    {isViewAgreementPermission && (
                      <AppTooltip title={t('agreementTemplate.actions.view')}>
                        <DeleteTemplateLabel
                          onClick={() => onViewDocument(item, index, productData)}
                        >
                          <ViewIcon />
                        </DeleteTemplateLabel>
                      </AppTooltip>
                    )}

                    {isUpdateAgreementPermission && (
                      <AppTooltip title={t('agreementTemplate.actions.edit')}>
                        <DeleteTemplateLabel
                          onClick={() => onEditDocument(item, index, productData)}
                        >
                          <EditDocumentIcon />
                        </DeleteTemplateLabel>
                      </AppTooltip>
                    )}

                    {isDeleteAgreementPermission && (
                      <AppTooltip title={t('agreementTemplate.actions.delete')}>
                        <DeleteTemplateLabel
                          onClick={() => {
                            setDocumentObj({
                              documentTypeId: item.documentTypeId,
                              'document type': item.documentTypeName,
                              'used for entity': item.usedForEntity,
                              product: productData.productName,
                              documentId: item.documentId,
                              productId: item.productId,
                              documentOrgId: item.documentOrgId,
                            });
                            setAgreementDeleteModal(true);
                          }}
                        >
                          <RemoveDocumentIcon />
                        </DeleteTemplateLabel>
                      </AppTooltip>
                    )}

                    {isViewAgreementPermission && (
                      <AppTooltip title={t('agreementTemplate.actions.download')}>
                        <DeleteTemplateLabel
                          onClick={() => {
                            downloadTemplate({
                              templateId: item.templateId,
                              fileName: `${item.documentTypeName}_${item.usedForEntity}_${productData.productName}`,
                            });
                          }}
                        >
                          <DownloadIcon />
                        </DeleteTemplateLabel>
                      </AppTooltip>
                    )}
                  </TableCell>
                ) : (
                  <TableCell align="left">
                    {isAddAgreementPermission && !item.templateId ? (
                      <AppTooltip title={t('agreementTemplate.actions.add')}>
                        <DeleteTemplateLabel
                          onClick={() => onAddDocument(item, productData.productName)}
                        >
                          <AddDocumentIcon />
                        </DeleteTemplateLabel>
                      </AppTooltip>
                    ) : (
                      <AddTemplateLabel active={'false'}>No possible actions</AddTemplateLabel>
                    )}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </CustomTableSmall>
          <DeleteEntityModal
            open={agreementDeleteModal}
            setOpen={setAgreementDeleteModal}
            entityName={`${productData.productName} - ${documentObj['document type']} - ${documentObj['used for entity']}`}
            entityType={t('agreement.delete.modal.title')}
            onConfirm={onDeleteDocument}
          />
        </>
      ) : null}

      {!isProductEditable && (
        <ModificationLog
          {...{
            createdBy: productData?.createdBy,
            createdOn: productData?.createdOn,
            updatedBy: productData?.updatedBy,
            updatedOn: productData?.updatedOn,
          }}
        />
      )}

      <AddTemplate
        open={showAddTemplateModal}
        onClose={() => {
          setShowAddTemplateModal(false);
        }}
        isLoading={isAddDocumentLoading}
        documentData={documentObj || {}}
        onHandleFormSubmit={(templateFormData) => onSubmitAddTemplate(templateFormData)}
      />

      <EditTemplateModal
        open={showEditTemplateModal}
        onClose={() => {
          setShowEditTemplateModal(false);
        }}
        isLoading={false}
        documentData={documentObj || {}}
        onHandleFormSubmit={(templateFormData: DocumentItem) => {
          editDocumentOrgId({
            documentOrgId: templateFormData.documentOrgId,
            productId: templateFormData.productId,
            uploadDocs: {
              documentItems: [
                {
                  documentId: templateFormData.documentId,
                },
              ],
            },
          });
        }}
      />

      <CancelModal
        isOpened={cancelPopUp}
        modalTitle={t('security.roles.roleForm.role.warning')}
        onDiscardChanges={onDiscardChanges}
        onSaveChanges={onSaveChanges}
        onExit={onExit}
      />
    </>
  );
}

export const ProtectedProductLinePage = () => {
  const { t } = useTranslation();
  const isViewProductPermission = usePermissions(PermissionKeys.ViewProducts);

  if (!isViewProductPermission) {
    return <NoPermissionMessage message={t('restrictions.tooltip')} />;
  }
  return <ViewProduct />;
};
