import { createTheme } from '@mui/material/styles';
import { FlashWhite, MainRed, Red, White } from './colorsVariables';

const AppMUITheme = createTheme({
  typography: {
    fontFamily: 'Gotham-Book',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  transitions: {
    duration: {
      enteringScreen: 200,
      leavingScreen: 200,
    },
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        popupIndicator: {
          color: MainRed,
        },
        clearIndicator: {
          background: `${MainRed} !important`,
          visibility: 'visible',
          width: '24px',
          height: '24px',
          '.MuiSvgIcon-root': {
            color: `${White} !important`,
          },
        },
        endAdornment: {
          top: 'calc(50% - 12px)',
          '.MuiSvgIcon-root': {
            color: MainRed,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '.MuiSvgIcon-root': {
            color: MainRed,
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          position: 'relative',
          zIndex: 0,
          '&:not([aria-disabled="true"]):after': {
            content: '""',
            left: 13,
            top: 13,
            height: 15,
            width: 15,
            position: 'absolute',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          color: Red,
          fontStyle: 'italic',
          fontFamily: 'Gotham-Book',
          '&.Mui-disabled': {
            color: Red,
          },
          '&.Mui-error': {
            color: Red,
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: `none`,
          '&:before': {
            display: 'none',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: '0',
          background: White,
          borderBottom: `1px solid ${FlashWhite}`,
          position: 'sticky',
          top: '0',
          zIndex: '20',
          flexDirection: 'row-reverse',
          '& .MuiAccordionSummary-expandIconWrapper': {
            marginRight: '10px',
          },
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {},
      },
    },
  },
});

export default AppMUITheme;
