import React, { useEffect, useState, useRef, ChangeEvent, useCallback } from 'react';
import { Grid, Typography } from '@mui/material';
import UserService from '../../services/userService';
import { User, AuthUser, AgencyType } from '../../types';
import {
  Cell,
  CellType,
  NewLinkState,
  AddNewLink,
  AdvancedSearchFieldType,
  InputsComponentProps,
  AdvancedSearchParam,
} from '../../types/customTable';
import { EntityType, Order, Status } from '../../types/query-params';
import CustomTable from '../../components/shared/CustomTable';
import {
  StatusSelectData,
  QuickTableFilter,
} from '../../components/shared/CustomTableFilters/interface';
import { SubmitHandler } from 'react-hook-form';
import AdvancedSearchInputs from '../../components/shared/CustomTableSearchFields';
import useDebounce from '../../hooks/useDebounce';
import {
  ProviderFetchingQuery,
  useAllAgents,
  useAppRolesByType,
} from '../../services/sellerServices';
import { RoleService } from '../../services/roleService';
import { UserAdvancedSearch } from './advanced-search-data-interface';
import usePermissions, { PermissionKeys } from '../../hooks/usePermissions';
import { useMemo } from 'react';
import { defaultUserValues } from '../../data/defaultAdvancedSearchFormValues';
import { useTranslation } from 'react-i18next';
import { useQuery } from '../../hooks/useQuery';
import { getSearchString } from '../../utils';
import { linkColor } from '../../Theme/colorsVariables';
import { useSaveSearchCriteria } from '../../hooks/useSaveSearchCriteria';
import useDebouncedQuery from '../../hooks/useDebouncedQuery';
import RoleType from '../../types/businessRoles';
import { AppPhoneInputViewOnly } from '../../components/shared/AppPhoneFieldViewOnly';

const SEARCH_KEY = 'userList.search';

export default function UserList() {
  const { t } = useTranslation();
  const roleIdQuery = useQuery('roleId');
  const isReadProviderUserPermission = usePermissions(PermissionKeys.ReadProviderUser);
  const isReadAgentUserPermission = usePermissions(PermissionKeys.ReadAgentUser);
  const isCreateProviderUserPermission = usePermissions(PermissionKeys.CreateProviderUser);
  const isCreateAgentUserPermission = usePermissions(PermissionKeys.CreateAgentUser);
  const isReadAgentRolesPermission = usePermissions(PermissionKeys.ReadAgentRoles);
  const isReadProviderRolesPermission = usePermissions(PermissionKeys.ReadProviderRoles);

  const {
    searchTerm,
    page,
    rowsPerPage,
    order,
    orderBy,
    status,
    assosiatedWith,
    setSearchTerm,
    setPage,
    setRowsPerPage,
    setOrder,
    setOrderBy,
    setStatus,
    setAssosiatedWith,
    resetSearch,
  } = useSaveSearchCriteria({
    searchKey: SEARCH_KEY,
  });

  const ref = useRef<any>();

  const tableHeadingsArray = useMemo(() => {
    const res: Record<string, string>[] = [
      { 'First Name': 'firstName' },
      { 'Last Name': 'lastName' },
      { Email: 'email' },
      { Phone: 'phone' },
      { Title: 'title' },
      { 'Associated With': 'associatedWith' },
      { 'Associated With Entity': 'associatedWithEntity' },
      { Status: 'status' },
      { '': '' },
    ];
    return res;
  }, []);

  const [userRows, setUserRows] = useState<Cell[][]>([]);
  const debounceValue = useDebounce(searchTerm, 300);
  const [dataAdvancedSearch, setDataAdvancedSearch] = useState<UserAdvancedSearch>();
  const [advancedSearchVisibility, setAdvancedSearchVisibility] = useState(false);

  const {
    data: usersData,
    isLoading: isUsersLoading,
    refetch,
  } = UserService.useUserAll({
    pageNumber: page + 1,
    pageSize: rowsPerPage,
    sortColumn: orderBy,
    sortDirection: order,
    name: getSearchString(debounceValue),
    associatedWith: assosiatedWith?.label === 'Any' ? '' : assosiatedWith?.value?.toString() || '',
    status: status && status?.label === 'Any' ? '' : status?.label,
    assignedRoles: roleIdQuery !== '' ? +roleIdQuery : undefined,
    shouldMatchAllCriteria: advancedSearchVisibility,
    advancedSearch: dataAdvancedSearch,
  });

  const { data: agencyContactClassifications } = useAppRolesByType(RoleType.agencyUser);

  const { data: providerContactClassifications } = useAppRolesByType(RoleType.businessWithOther);

  const { data: roles } = RoleService.useAll(
    {},
    { enabled: isReadAgentRolesPermission || isReadProviderRolesPermission },
  );

  const { isLoading: isPreUsersLoading } = useDebouncedQuery(refetch, true);

  const [filterList, setFilterList] = useState<User[]>([]);
  const { data: agencies } = useAllAgents({});
  const { data: providerInfo } = ProviderFetchingQuery();

  const assignedRoles = useMemo(() => {
    if (assosiatedWith?.label === 'Any') {
      return roles
        ? roles.data
            .map((item) => ({
              value: item.roleId.toString(),
              text: item.roleName,
            }))
            .sort((a, b) => a.text.localeCompare(b.text))
        : [];
    }
    if (assosiatedWith?.label === 'Agent') {
      return roles
        ? roles.data
            .filter((item) => item.assosiatedWith === EntityType.Agency)
            .map((item) => ({
              value: item.roleId.toString(),
              text: item.roleName,
            }))
            .sort((a, b) => a.text.localeCompare(b.text))
        : [];
    }
    if (assosiatedWith?.label === 'Provider') {
      return roles
        ? roles.data
            .filter((item) => item.assosiatedWith === EntityType.Provider)
            .map((item) => ({
              value: item.roleId.toString(),
              text: item.roleName,
            }))
            .sort((a, b) => a.text.localeCompare(b.text))
        : [];
    }
    return [];
  }, [assosiatedWith?.label, roles]);

  const businessRole = useMemo(() => {
    if (assosiatedWith?.label === EntityType.Any) {
      return [...(providerContactClassifications || []), ...(agencyContactClassifications || [])]
        .map((item) => ({
          value: item.roleId.toString(),
          text: item.roleName,
        }))
        .sort((a, b) => a.text.localeCompare(b.text));
    }
    if (assosiatedWith?.label === EntityType.Agent) {
      return [...(agencyContactClassifications || [])]
        .map((item) => ({
          value: item.roleId.toString(),
          text: item.roleName,
        }))
        .sort((a, b) => a.text.localeCompare(b.text));
    }
    if (assosiatedWith?.label === EntityType.Provider) {
      return [...(providerContactClassifications || [])]
        .map((item) => ({
          value: item.roleId.toString(),
          text: item.roleName,
        }))
        .sort((a, b) => a.text.localeCompare(b.text));
    }

    return [];
  }, [assosiatedWith?.label, agencyContactClassifications, providerContactClassifications]);
  const entityDetails = useMemo(
    () =>
      agencies
        ? agencies
            .sort((a, b) => a.agencyName.localeCompare(b.agencyName))
            .map((item: AgencyType) => ({
              value: item.agencyId.toString(),
              text: item.agencyName,
            }))
        : [],
    [agencies],
  );
  const [err, setErr] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState(0);
  /** This is to identify which method to use in pagination */
  const [pagination, setPagination] = useState<boolean>(true);

  const [addNewLink, setAddNewLink] = useState<AddNewLink>({
    link: '',
    state: NewLinkState.active || NewLinkState.disabled,
  });

  const menuItems: StatusSelectData[] = [
    { value: Status.Any, text: t('status.any') },
    { value: Status.Active, text: t('status.active') },
    { value: Status.Inactive, text: t('status.inactive') },
  ];

  const menuItemsUser: StatusSelectData[] = [
    { value: EntityType.Any, text: t('status.any') },
    { value: EntityType.Agency, text: t('status.agency') },
    { value: EntityType.Provider, text: t('status.provider') },
  ];

  const valueRef = useRef<any>();

  const { mutate: changeUserPasswordLink } = UserService.usePasswordLinkQuery();

  const handleEntityChange = (value: { label: EntityType; value: EntityType }) => {
    setAssosiatedWith(value);
    setPage(0);
  };

  const handleStatusChange = (value: { label: Status; value: Status }) => {
    setStatus(value);
    setPage(0);
  };

  const passwordStatus = useCallback(
    (item: AuthUser) => {
      if (item.status === 1 && item.isUserVerified && item.authUserId !== '') {
        changeUserPasswordLink({
          mailId: item.email,
          userName: item.firstName + ' ' + item.lastName,
        });
      }
    },
    [changeUserPasswordLink],
  );

  const usersRowsMapper = useCallback(
    (rawData: AuthUser[]): void => {
      const mappedCell: Cell[][] = rawData.map((rawUserData: AuthUser) => [
        {
          data: rawUserData.firstName,
          type: CellType.Link,
          linkTo: `/dashboard/User/${rawUserData.userId}`,
          styles: { color: linkColor },
        },
        {
          data: rawUserData.lastName,
          type: CellType.Info,
        },
        {
          data: rawUserData.email ? rawUserData.email : '-',
          type: CellType.Info,
          styles: {
            maxWidth: '240px',
            wordBreak: 'break-word',
          },
        },
        {
          data: (
            <AppPhoneInputViewOnly
              phone={
                (rawUserData?.phoneNumberCountryCode?.replace('+', '') || '') +
                (rawUserData?.phoneNumber || '')
              }
            />
          ),
          type: CellType.Info,
        },
        {
          data: rawUserData.title,
          type: CellType.Info,
        },
        {
          data: rawUserData.orgTypeName
            ? rawUserData.orgTypeName === 'Agency'
              ? 'Agent'
              : rawUserData.orgTypeName
            : '-',
          type: CellType.Info,
        },
        {
          data: rawUserData.legalName ? rawUserData.legalName : '-',
          type: CellType.Info,
        },
        {
          data: rawUserData.status === 1 ? 'Active' : 'Inactive',
          type: CellType.StatusFilter,
          defaultStatus: 'Active',
        },
        {
          data: {
            callbackPassword: () => passwordStatus(rawUserData),
            rawUserData,
          },
          type: CellType.Password,
        },
      ]);
      setUserRows(mappedCell);
    },
    [passwordStatus],
  );

  const selectArrayUser: QuickTableFilter[] = [
    {
      label: t('security.users.userList.entity'),
      status: assosiatedWith,
      handleFilter: handleEntityChange,
      menuItemsSelect: menuItemsUser,
      type: 'single',
      disabled:
        (isReadAgentUserPermission && !isReadProviderUserPermission) ||
        (!isReadAgentUserPermission && isReadProviderUserPermission),
    },
    {
      label: t('security.roles.rolesList.status'),
      status: status,
      handleFilter: handleStatusChange,
      menuItemsSelect: menuItems,
      type: 'single',
    },
  ];

  const advancedSearchInputParams = useMemo<AdvancedSearchParam[]>(() => {
    let specificFields: AdvancedSearchParam[] = [];

    if (assosiatedWith?.value === EntityType.Agency) {
      specificFields = [
        {
          label: t('user.advanced.search.associatedWithEntity'),
          name: 'entityId',
          type: AdvancedSearchFieldType.Select,
          selectTypeParams: entityDetails,
        },
      ];
    }

    if (assosiatedWith?.value === EntityType.Provider && providerInfo) {
      specificFields = [
        {
          label: providerInfo.legalName, //this is short fix to show the provider name
          name: 'entityId',
          type: AdvancedSearchFieldType.Select,
          selectTypeParams: [{ value: providerInfo.legalName, text: providerInfo.legalName }],
          disabled: true,
        },
      ];
    }

    return [
      {
        label: t('security.users.header.table.firstname'),
        name: 'firstName',
        type: AdvancedSearchFieldType.Text,
        selectTypeParams: null,
      },
      {
        label: t('security.users.header.table.lastname'),
        name: 'lastName',
        type: AdvancedSearchFieldType.Text,
        selectTypeParams: null,
      },
      {
        label: t('security.roles.advance.user.search.contactClassification'),
        name: 'businessRoleId',
        type: AdvancedSearchFieldType.Select,
        selectTypeParams: businessRole,
      },
      ...(assignedRoles.length > 0
        ? [
            {
              label: t('user.advanced.search.applicationRole'),
              name: 'assignedRoleId',
              type: AdvancedSearchFieldType.Select,
              selectTypeParams: assignedRoles,
            },
          ]
        : []),
      ...specificFields,
      {
        label: t('security.role.advanceSearch.email'),
        name: 'email',
        type: AdvancedSearchFieldType.Text,
        selectTypeParams: null,
      },
      {
        label: t('security.role.advanceSearch.title'),
        name: 'title',
        type: AdvancedSearchFieldType.Text,
        selectTypeParams: null,
      },
    ];
  }, [assosiatedWith?.value, providerInfo, t, businessRole, assignedRoles, entityDetails]);

  useEffect(() => {
    if (!usersData) {
      return;
    } else {
      setTotalCount(usersData.totalCount);
      usersRowsMapper(usersData.data);
    }
  }, [
    usersData,
    usersRowsMapper,
    advancedSearchVisibility,
    roleIdQuery,
    setSearchTerm,
    setStatus,
    setAssosiatedWith,
  ]);

  useEffect(() => {
    if (!advancedSearchVisibility) {
      setDataAdvancedSearch({
        firstName: '',
        lastName: '',
        entityId: undefined,
        businessRoleId: undefined,
        assignedRoleId: roleIdQuery !== '' ? +roleIdQuery : undefined,
        email: '',
        title: '',
        entityType: '',
        associatedWithId: undefined,
      });
    } else {
      resetSearch();
    }
  }, [
    advancedSearchVisibility,
    resetSearch,
    roleIdQuery,
    setAssosiatedWith,
    setSearchTerm,
    setStatus,
    usersRowsMapper,
  ]);

  /**
   * hitting API for sent link to change password for user
   */

  const handlePageChange = useCallback(
    (event: ChangeEvent<unknown>, newPage: number) => {
      setPage(newPage - 1);
    },
    [setPage],
  );

  useEffect(() => {
    const checkIfClickedOutside = (e: Event) => {
      if (filterList.length > 0 && ref.current && !ref.current.contains(e.target)) {
        setFilterList([]);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [filterList]);

  const handleRowsPerPageChange = (newLimit: number): void => {
    setRowsPerPage(newLimit);
    setPage(0);
  };

  const handleAdvanceSearch: SubmitHandler<UserAdvancedSearch> = useCallback(
    (data: any) => {
      let hasError = false;
      Object.keys(data).forEach((item: any) => {
        if (data[item] !== '') {
          setErr(false);
          hasError = true;
        }
      });
      if (!hasError) {
        setErr(true);
      } else {
        const dataToPost: UserAdvancedSearch = {
          firstName: data.firstName || undefined,
          lastName: data.lastName || undefined,
          entityId: +data.entityId?.value || undefined,
          businessRoleId: +data.businessRoleId?.value || undefined,
          assignedRoleId: +data.assignedRoleId?.value || undefined,
          title: data.title || undefined,
          email: data.email || undefined,
          entityType: data.entityType?.value || undefined,
          associatedWithEntityId: data.entityId || undefined,
        };

        if (isReadProviderUserPermission && !isReadAgentUserPermission) {
          dataToPost.associatedWithId = 1;
        }
        if (!isReadProviderUserPermission && isReadAgentUserPermission) {
          dataToPost.associatedWithId = 2;
        }

        setDataAdvancedSearch(dataToPost);
      }
      setPage(0);
    },
    [isReadAgentUserPermission, isReadProviderUserPermission, setPage],
  );

  const onResetAdvancedSearch = useCallback((): void => {
    setPage(0);
    handleAdvanceSearch(defaultUserValues);
  }, [handleAdvanceSearch, setPage]);

  const onHandleSetOrder = useCallback(
    (newOrder: Order, newOrderBy: string): void => {
      if (!userRows) return;
      setOrder(newOrder);

      setOrderBy(newOrderBy);
    },
    [userRows, setOrder, setOrderBy],
  );

  const userSearchData = useCallback(
    (searchedVal: string) => {
      setSearchTerm(searchedVal);
      setPage(0);
    },
    [setSearchTerm, setPage],
  );

  useEffect(() => {
    if (roleIdQuery) {
      resetSearch();
    }
  }, [resetSearch, roleIdQuery]);

  useEffect(() => {
    if (isCreateProviderUserPermission || isCreateAgentUserPermission) {
      setAddNewLink({
        link: '/dashboard/User/Add',
        state: NewLinkState.active,
      });
    } else {
      setAddNewLink({ link: '', state: NewLinkState.disabled });
    }
  }, [isCreateProviderUserPermission, isCreateAgentUserPermission]);

  useEffect(() => {
    if (isReadAgentUserPermission && !isReadProviderUserPermission) {
      setAssosiatedWith({ label: 'Agent', value: 'Agency' });
    }
    if (!isReadAgentUserPermission && isReadProviderUserPermission) {
      setAssosiatedWith({ label: 'Provider', value: 'Provider' });
    }
  }, [isReadAgentUserPermission, isReadProviderUserPermission, setAssosiatedWith]);

  return isReadProviderUserPermission || isReadAgentUserPermission ? (
    <>
      <CustomTable
        isDataLoading={isUsersLoading || isPreUsersLoading}
        goError={false}
        page={page}
        rowsPerPage={rowsPerPage}
        data={userRows}
        handlePageChange={handlePageChange}
        valueRef={valueRef}
        setPage={setPage}
        tableHeadingsArray={tableHeadingsArray}
        setPagination={setPagination}
        order={order}
        orderBy={orderBy}
        tableTitle="Users"
        placeHolder={t('security.users.userList.searchUser.search')}
        handleRowsPerPageChange={handleRowsPerPageChange}
        advancedSearchVisibility={advancedSearchVisibility}
        setAdvancedSearchVisibility={setAdvancedSearchVisibility}
        onSubmitAdvancedSearch={handleAdvanceSearch}
        openAdvancedSearch={usersRowsMapper}
        onResetAdvancedSearch={onResetAdvancedSearch}
        searchValue={searchTerm}
        handleSearchValue={userSearchData}
        selectArray={selectArrayUser}
        addNewLink={addNewLink}
        isDataPagination={pagination}
        handleRequestSort={onHandleSetOrder}
        total={totalCount}
        err={err}
        InputsComponent={(props: InputsComponentProps) => (
          <AdvancedSearchInputs
            {...{
              formMethods: props.formMethods,
              advancedSearchInputParams,
            }}
          />
        )}
      />
    </>
  ) : (
    <Grid container textAlign="center">
      <Grid item md={12} xs={12}>
        <Typography fontFamily="Gotham-Bold" fontSize="20px" mt={10}>
          {t('restrictions.tooltip')}
        </Typography>
      </Grid>
    </Grid>
  );
}
