import { Box } from '@mui/material';
import { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { AppTextField } from '../AppTextField';
import { SingleSelectProps } from './types';
import { AppAutocomplete } from '../AppAutocomplete';
import AppListItem from '../AppListItem';

export default function AppAutocompleteSingleSelect({
  name,
  rules,
  options = [],
  disabled,
  label,
  errorMessage = '',
  control,
  onChange,
  helperText,
  enableAnyOption = true,
  size,
}: SingleSelectProps) {
  const optionsArray = useMemo(() => {
    if (!rules?.required && enableAnyOption) {
      return [{ label: 'Any', value: undefined }, { label: '', value: undefined }, ...options];
    }
    return [{ label: '', value: undefined }, ...options];
  }, [enableAnyOption, options, rules?.required]);

  return (
    <Box>
      <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={{ label: '', value: undefined }}
        render={({ field }) => {
          return (
            <AppAutocomplete
              {...field}
              data-cy={`select-${name}`}
              size={size}
              isOptionEqualToValue={(option, value) => {
                return option.label === value.label;
              }}
              disablePortal
              options={optionsArray}
              fullWidth
              forcePopupIcon
              value={field.value}
              disabled={disabled}
              getOptionDisabled={(option) => {
                return name === 'legalOrganization' && !option?.additionalInfo?.isActive;
              }}
              renderInput={(params) => (
                <AppTextField
                  {...params}
                  label={label}
                  disabled={disabled}
                  required={!!rules?.required}
                  fullWidth
                  error={!!errorMessage}
                  errorMessage={errorMessage}
                  helperText={helperText}
                />
              )}
              getOptionLabel={(option) =>
                option.label
                  ? option.label
                      .replaceAll('<span class="vl"></span>', ' | ')
                      .replaceAll('<i>', '')
                      .replaceAll('</i>', '')
                  : ''
              }
              renderOption={(props, option) => {
                if (option.label) {
                  return (
                    <AppListItem
                      {...props}
                      key={JSON.stringify(option)}
                      data-cy-option={'select-option'}
                      data-cy={`select-option-${option.label}`}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: option.label,
                        }}
                      ></span>
                    </AppListItem>
                  );
                }
              }}
              onChange={(e, data, reason) => {
                if (data?.label === 'Any') {
                  field.onChange({ label: 'Any', value: undefined }, reason);
                  if (onChange) {
                    onChange({ label: 'Any', value: undefined }, reason);
                  }
                  return;
                }
                field.onChange(data, reason);
                if (onChange) {
                  onChange(data, reason);
                }
              }}
            />
          );
        }}
      />
    </Box>
  );
}
