import { ConfigurationService } from '../../../services/configurationService';
import { TableCell, TableBody, Box, InputAdornment, TableRow } from '@mui/material';
import PageHeaderContentHost from '../../../components/shared/AppPageHeaderContentHost';
import { useNavigate } from 'react-router-dom';
import { SearchIcon } from '../../../components/AppSearch/styles';
import {
  CustomSearch,
  ClearIconEndAdornment,
  SingleSearchBox,
} from '../../../components/shared/CustomTableSingleSearch/styles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomTableHead from '../../../components/shared/CustomTableHead';
import { Order } from '../../../types/query-params';
import { TenantSetupItem } from '../../../types/configurations';
import { FlexBox } from '../../../components/shared/FlexBox';
import DataTable from '../../../components/table/DataTable';
import InfoIcon from '@mui/icons-material/Info';
import AppTooltip from '../../../components/shared/AppTooltip';
import usePermissions, { PermissionKeys } from '../../../hooks/usePermissions';
import { CustomBoxPagination } from '../../../components/shared/CustomTable/styles';

export default function TenantSetupPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isEnabled = usePermissions(PermissionKeys.Configuration);
  const { data: tenantSetupInfo } = ConfigurationService.useTenantSetup({
    enabled: isEnabled,
  });

  const [searchValue, setSearchValue] = useState('');
  const [order, setOrder] = useState(Order.ASC);
  const [orderBy, setOrderBy] = useState('constantType');
  const [tableContainerRef, setTableContainerRef] = useState<HTMLDivElement | null>(null);

  const tableHeadingsArray = useMemo(() => {
    return [
      { 'Constant Type': 'constantType' },
      { Description: 'description' },
      { 'Constant Value': 'constantValue' },
    ] as Record<string, string>[];
  }, []);

  const onHandleSetOrder = useCallback(
    (newOrder: Order, newOrderBy: string): void => {
      setOrder(newOrder);
      setOrderBy(newOrderBy);
    },
    [setOrder, setOrderBy],
  );

  const sortedData = useMemo(() => {
    const filteredValues =
      searchValue.length > 2
        ? tenantSetupInfo?.filter((value) => value.constantType.toLowerCase().includes(searchValue))
        : tenantSetupInfo || [];
    const sortedValues = filteredValues?.sort((a, b) => {
      if (order === Order.ASC) {
        return `${a[orderBy as keyof TenantSetupItem]}`.localeCompare(
          `${b[orderBy as keyof TenantSetupItem]}`,
        );
      }
      if (order === Order.DESC) {
        return `${b[orderBy as keyof TenantSetupItem]}`.localeCompare(
          `${a[orderBy as keyof TenantSetupItem]}`,
        );
      }
      return `${a[orderBy as keyof TenantSetupItem]}`.localeCompare(
        `${b[orderBy as keyof TenantSetupItem]}`,
      );
    });
    return sortedValues;
  }, [tenantSetupInfo, order, orderBy, searchValue]);

  useEffect(() => {
    if (!isEnabled) {
      navigate('/NotFound', { state: { error: t('restrictions.tooltip') } });
    }
  }, [isEnabled, navigate, t]);

  return (
    <>
      <Box my={1}>
        <PageHeaderContentHost name="Tenant Setup" />
      </Box>
      <SingleSearchBox mb={2}>
        <CustomSearch
          sx={{ width: '300px' }}
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          placeholder={'Search'}
          aria-describedby="outlined-weight-helper-text"
          size="small"
          InputProps={{
            'aria-label': 'weight',
            endAdornment: (
              <InputAdornment position="end">
                {searchValue && (
                  <ClearIconEndAdornment
                    onClick={() => {
                      setSearchValue('');
                    }}
                  />
                )}
                {!searchValue && <SearchIcon />}
              </InputAdornment>
            ),
          }}
          error={searchValue.length < 3 && searchValue !== ''}
          helperText={searchValue.length < 3 && searchValue !== '' && t('appTextField.helperText')}
        />
        <AppTooltip title={'Enter Constant Type to search'} placement="bottom">
          <InfoIcon fontSize="inherit" color="disabled" />
        </AppTooltip>
      </SingleSearchBox>
      <CustomBoxPagination mb={1}>
        {`${t('shared.tablePagination.showing')} ${sortedData?.length === 0 ? 0 : 1} - ${
          sortedData?.length || 0
        } ${t('shared.tablePagination.of')} ${tenantSetupInfo?.length || 0} ${t(
          'shared.tablePagination.records',
        )}`}
      </CustomBoxPagination>
      <FlexBox
        className="MAINTABLE"
        sx={{ flex: '1 1 auto', flexDirection: 'column', height: '100%' }}
        ref={setTableContainerRef}
      >
        <DataTable tableMaxHeight={`${tableContainerRef?.getBoundingClientRect().height}px`}>
          <CustomTableHead
            heading={tableHeadingsArray}
            order={order}
            orderBy={orderBy}
            onRequestSort={onHandleSetOrder}
          />
          <TableBody>
            {sortedData?.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.constantType}</TableCell>
                <TableCell>{item.description}</TableCell>
                <TableCell>{item.constantValue}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </DataTable>
      </FlexBox>
    </>
  );
}
