import {
  TableCell,
  TableBody,
  Box,
  InputAdornment,
  TableRow,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import PageHeaderContentHost from '../../../components/shared/AppPageHeaderContentHost';
import { useNavigate, useParams } from 'react-router-dom';
import { SearchIcon } from '../../../components/AppSearch/styles';
import {
  CustomSearch,
  ClearIconEndAdornment,
  SingleSearchBox,
} from '../../../components/shared/CustomTableSingleSearch/styles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomTableHead from '../../../components/shared/CustomTableHead';
import { Order } from '../../../types/query-params';
import { AlignCenterBox, FlexBox, SpaceBetweenBox } from '../../../components/shared/FlexBox';
import DataTable from '../../../components/table/DataTable';
import InfoIcon from '@mui/icons-material/Info';
import { ConfigurationService } from '../../../services/configurationService';
import { AssetMake } from '../../../types/configurations';
import { useQuery } from '../../../hooks/useQuery';
import { AppTextField } from '../../../components/shared/AppTextField';
import AppTooltip from '../../../components/shared/AppTooltip';
import usePermissions, { PermissionKeys } from '../../../hooks/usePermissions';

export default function AssetMakes() {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xl'));

  const { t } = useTranslation();
  const navigate = useNavigate();
  const isEnabled = usePermissions(PermissionKeys.Configuration);
  const { dealerGroupTypeId } = useParams();
  const dealerTypeGroupName = useQuery('dealerTypeGroupName');
  const dealerType = useQuery('dealerType');
  const { data: assetMakes } = ConfigurationService.useAssetMakes(Number(dealerGroupTypeId), {
    enabled: isEnabled,
  });

  const [searchValue, setSearchValue] = useState('');
  const [order, setOrder] = useState(Order.ASC);
  const [orderBy, setOrderBy] = useState('assetMakeName');
  const [tableContainerRef, setTableContainerRef] = useState<HTMLDivElement | null>(null);

  const tableHeadingsArray = useMemo(() => {
    return [{ 'Asset Make': 'assetMakeName' }] as Record<string, string>[];
  }, []);

  const onHandleSetOrder = useCallback(
    (newOrder: Order, newOrderBy: string): void => {
      setOrder(newOrder);
      setOrderBy(newOrderBy);
    },
    [setOrder, setOrderBy],
  );

  const sortedData = useMemo(() => {
    const filteredValuesBySearch =
      searchValue.length > 2
        ? assetMakes?.filter((value) =>
            value.assetMakeName.toLowerCase().includes(searchValue.toLowerCase()),
          )
        : assetMakes;

    const sortedValues = filteredValuesBySearch?.sort((a, b) => {
      if (order === Order.ASC) {
        return `${a[orderBy as keyof AssetMake]}`.localeCompare(`${b[orderBy as keyof AssetMake]}`);
      }
      if (order === Order.DESC) {
        return `${b[orderBy as keyof AssetMake]}`.localeCompare(`${a[orderBy as keyof AssetMake]}`);
      }
      return `${a[orderBy as keyof AssetMake]}`.localeCompare(`${b[orderBy as keyof AssetMake]}`);
    });
    return sortedValues;
  }, [assetMakes, order, orderBy, searchValue]);

  useEffect(() => {
    if (!isEnabled) {
      navigate('/NotFound', { state: { error: t('restrictions.tooltip') } });
    }
  }, [isEnabled, navigate, t]);

  return (
    <>
      <Box my={1}>
        <PageHeaderContentHost name="Asset Makes" />
      </Box>
      <Grid container spacing={isSmall ? 1 : 2} mb={1}>
        <Grid item xs={3}>
          <AppTextField
            label={'Dealer Type Group'}
            size="small"
            value={dealerTypeGroupName}
            disabled
          />
        </Grid>
        <Grid item xs={3}>
          <AppTextField label={'Dealer Type'} size="small" value={dealerType} disabled />
        </Grid>
      </Grid>
      <AlignCenterBox mb={2}>
        <SpaceBetweenBox sx={{ width: '100%' }}>
          <SingleSearchBox>
            <CustomSearch
              sx={{ width: '300px' }}
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              placeholder={'Search'}
              aria-describedby="outlined-weight-helper-text"
              size="small"
              InputProps={{
                'aria-label': 'weight',
                endAdornment: (
                  <InputAdornment position="end">
                    {searchValue && (
                      <ClearIconEndAdornment
                        onClick={() => {
                          setSearchValue('');
                        }}
                      />
                    )}
                    {!searchValue && <SearchIcon />}
                  </InputAdornment>
                ),
              }}
              error={searchValue.length < 3 && searchValue !== ''}
              helperText={
                searchValue.length < 3 && searchValue !== '' && t('appTextField.helperText')
              }
            />
            <AppTooltip title={'Enter Asset Make to search'} placement="bottom">
              <InfoIcon fontSize="inherit" color="disabled" />
            </AppTooltip>
          </SingleSearchBox>
        </SpaceBetweenBox>
      </AlignCenterBox>
      <FlexBox
        className="MAINTABLE"
        sx={{ flex: '1 1 auto', flexDirection: 'column', height: '100%' }}
        ref={setTableContainerRef}
      >
        <DataTable tableMaxHeight={`${tableContainerRef?.getBoundingClientRect().height}px`}>
          <CustomTableHead
            heading={tableHeadingsArray}
            order={order}
            orderBy={orderBy}
            onRequestSort={onHandleSetOrder}
          />
          <TableBody>
            {sortedData?.map((item) => (
              <TableRow key={item.assetMakeId}>
                <TableCell>{item.assetMakeName}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </DataTable>
      </FlexBox>
    </>
  );
}
