import styled from 'styled-components';
import { linkColor } from '../../../Theme/colorsVariables';
import { breakPointMedium } from '../../../utils';
import { CenterCenterBox } from '../../shared/FlexBox';

export const StatusText = styled('p')`
  color: ${(props: { active: boolean }) => (props.active ? linkColor : '')};
  text-decoration: ${(props: { active: boolean }) => (props.active ? 'underline' : '')};
  cursor: ${(props: { active: boolean }) => (props.active ? 'pointer' : '')};
  margin: 0;
`;

export const IconWrapper = styled(CenterCenterBox)<{
  isallowed: 'true' | 'false';
  iconlabel: string;
}>`
  margin: 0 4px;
  :hover {
    cursor: ${(props) => (props.isallowed === 'true' ? 'pointer' : 'not-allowed')};
  }
  svg {
    ${breakPointMedium('width: 20px; height:20px')}
  }
`;
