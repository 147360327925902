import { ConfigurationService } from '../../../services/configurationService';
import { TableCell, TableBody, Box, InputAdornment, TableRow } from '@mui/material';
import PageHeaderContentHost from '../../../components/shared/AppPageHeaderContentHost';
import { useNavigate } from 'react-router-dom';
import { SearchIcon } from '../../../components/AppSearch/styles';
import {
  CustomSearch,
  ClearIconEndAdornment,
  SingleSearchBox,
} from '../../../components/shared/CustomTableSingleSearch/styles';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomTableHead from '../../../components/shared/CustomTableHead';
import { Order } from '../../../types/query-params';
import { LegalEntityType } from '../../../types/configurations';
import { FlexBox } from '../../../components/shared/FlexBox';
import DataTable from '../../../components/table/DataTable';
import InfoIcon from '@mui/icons-material/Info';
import { ActiveText } from '../../../components/shared/AppPageHeaderContentHost/styles';
import { FontSizeMed } from '../../../Theme/screensVariables';
import AppTooltip from '../../../components/shared/AppTooltip';
import usePermissions, { PermissionKeys } from '../../../hooks/usePermissions';

export default function LegalOrganizationPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isEnabled = usePermissions(PermissionKeys.Configuration);
  const { data: legalOrganizations } = ConfigurationService.useLegalEntityTypes({
    enabled: isEnabled,
  });

  const [searchValue, setSearchValue] = useState('');
  const [order, setOrder] = useState(Order.ASC);
  const [orderBy, setOrderBy] = useState('legalEntityTypeName');
  const [tableContainerRef, setTableContainerRef] = useState<HTMLDivElement | null>(null);

  const tableHeadingsArray = useMemo(() => {
    return [
      { 'Legal Organization Name': 'legalEntityTypeName' },
      { 'Display Name': 'legalEntityTypeDisplayName' },
      { Status: 'status' },
    ] as Record<string, string>[];
  }, []);

  const onHandleSetOrder = useCallback(
    (newOrder: Order, newOrderBy: string): void => {
      setOrder(newOrder);
      setOrderBy(newOrderBy);
    },
    [setOrder, setOrderBy],
  );

  const sortedData = useMemo(() => {
    const filteredValues =
      searchValue.length > 2
        ? legalOrganizations?.filter((value) =>
            value.legalEntityTypeName.toLowerCase().includes(searchValue),
          )
        : legalOrganizations;
    const sortedValues = filteredValues?.sort((a, b) => {
      if (order === Order.ASC) {
        return `${a[orderBy as keyof LegalEntityType]}`.localeCompare(
          `${b[orderBy as keyof LegalEntityType]}`,
        );
      }
      if (order === Order.DESC) {
        return `${b[orderBy as keyof LegalEntityType]}`.localeCompare(
          `${a[orderBy as keyof LegalEntityType]}`,
        );
      }
      return `${a[orderBy as keyof LegalEntityType]}`.localeCompare(
        `${b[orderBy as keyof LegalEntityType]}`,
      );
    });
    return sortedValues;
  }, [legalOrganizations, order, orderBy, searchValue]);

  useEffect(() => {
    if (!isEnabled) {
      navigate('/NotFound', { state: { error: t('restrictions.tooltip') } });
    }
  }, [isEnabled, navigate, t]);

  return (
    <>
      <Box my={1}>
        <PageHeaderContentHost name="Legal Organizations" />
      </Box>
      <SingleSearchBox mb={2}>
        <CustomSearch
          sx={{ width: '300px' }}
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          placeholder={'Search'}
          aria-describedby="outlined-weight-helper-text"
          size="small"
          InputProps={{
            'aria-label': 'weight',
            endAdornment: (
              <InputAdornment position="end">
                {searchValue && (
                  <ClearIconEndAdornment
                    onClick={() => {
                      setSearchValue('');
                    }}
                  />
                )}
                {!searchValue && <SearchIcon />}
              </InputAdornment>
            ),
          }}
          error={searchValue.length < 3 && searchValue !== ''}
          helperText={searchValue.length < 3 && searchValue !== '' && t('appTextField.helperText')}
        />
        <AppTooltip
          title={'Enter Legal Organization Name or Display Name to search'}
          placement="bottom"
        >
          <InfoIcon fontSize="inherit" color="disabled" />
        </AppTooltip>
      </SingleSearchBox>
      <FlexBox
        className="MAINTABLE"
        sx={{ flex: '1 1 auto', flexDirection: 'column', height: '100%' }}
        ref={setTableContainerRef}
      >
        <DataTable tableMaxHeight={`${tableContainerRef?.getBoundingClientRect().height}px`}>
          <CustomTableHead
            heading={tableHeadingsArray}
            order={order}
            orderBy={orderBy}
            onRequestSort={onHandleSetOrder}
          />
          <TableBody>
            {sortedData?.map((item) => (
              <TableRow key={item.legalEntityTypeId}>
                <TableCell>{item.legalEntityTypeName}</TableCell>
                <TableCell>{item.legalEntityTypeDisplayName}</TableCell>
                <TableCell>
                  <ActiveText
                    active={`${item.status}`}
                    sx={{ fontSize: FontSizeMed + ' !important' }}
                  >
                    {item.status ? (
                      <span>{t('security.users.userList.active')}</span>
                    ) : (
                      <span>{t('security.users.userList.inactive')}</span>
                    )}
                  </ActiveText>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </DataTable>
      </FlexBox>
    </>
  );
}
