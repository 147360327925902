import { AppState, Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useContext, useEffect } from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { reactPlugin } from '../appInsights';
import { useToast } from '../hooks/useToast';
import { ConfigurationService } from '../services/configurationService';
import AppMUITheme from '../Theme/materialiUITheme';
import { DeploymentStatusContext } from '../context/DeploymentStatus';
import { QuickConfirmationModalContext } from '../context/QuickModal';
import { useQueryClient } from 'react-query';

export const TabsComunicationChannel = new BroadcastChannel('LogoutChannel');

export const DeploymentStatusProvider = () => {
  const { isDeploymentRunning } = useContext(DeploymentStatusContext);
  const { setQuickModal } = useContext(QuickConfirmationModalContext);
  const { logout } = useAuth0();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isDeploymentRunning) {
      setQuickModal({
        isOpen: true,
        modalTitle: 'Info',
        message:
          'The site is undergoing scheduled maintenance. You will be logged out. We are sorry for the inconvenience. Please try again in 15 minutes.',
        yesCallBack() {
          TabsComunicationChannel.postMessage('logout');
          logout();
        },
      });
      setTimeout(() => {
        TabsComunicationChannel.postMessage('logout');
        logout();
      }, 10000);
    } else {
      setQuickModal({ isOpen: false, modalTitle: '', message: '' });
    }
  }, [isDeploymentRunning, logout, setQuickModal]);

  useEffect(() => {
    TabsComunicationChannel.onmessage = (ev) => {
      if (ev.data === 'logout') {
        logout();
      }
      if (ev.data === 'permissionsChange') {
        queryClient.invalidateQueries('user');
      }
    };
  }, [logout, queryClient]);

  return <Outlet />;
};

export const AppProviders = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const onRedirectCallback = (appState?: AppState | undefined) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  const [searchParams] = useSearchParams();
  const { data: supportInformation } = ConfigurationService.useSupportInfo();

  useEffect(() => {
    if (searchParams.has('error') && supportInformation) {
      toast.error(
        `User is inactive, please contact ${supportInformation?.supportEmail} or ${supportInformation?.supportPhoneNumber}`,
        { persist: true },
      );
      navigate('/login');
    }
  }, [navigate, searchParams, supportInformation, toast]);

  if (searchParams.has('error')) {
    return <></>;
  }

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ''}
        redirectUri={process.env.REACT_APP_AUTH_REDIRECT_URL}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE}
        scope={process.env.REACT_APP_AUTH0_SCOPE}
        onRedirectCallback={onRedirectCallback}
        useRefreshTokens={true}
        cacheLocation="localstorage"
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ThemeProvider theme={AppMUITheme}>
            <DeploymentStatusProvider />
          </ThemeProvider>
        </LocalizationProvider>
      </Auth0Provider>
    </AppInsightsContext.Provider>
  );
};
